<template>
  <AccountsCard />
</template>

<script>
import AccountsCard from '@/components/AccountsCard';

export default {
  components: {
    AccountsCard
  }
};
</script>
